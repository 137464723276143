import React, { useState } from "react";

import BookModal from "../../../components/BookModal/BookModal";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import { BookContext } from "../../../context/BookingContext/BookingContext";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";

function BannerText({ bannerItem, bookingFor }) {
  const { handleShow } = useContext(BookContext);
  const [isShowMore, setIsShowMore] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <div className="left-content">
        <h1 className="title banner-title">{bannerItem?.title}</h1>
        <p className="text banner-text">
          {!isShowMore
            ? `${bannerItem?.desc.slice(0, 453)} ...`
            : bannerItem?.desc}
        </p>
        <div className="d-flex align-items-center gap-3 mt-4">
          <Button onClick={toggleReadMoreLess} className="btn-book btns">
            {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
          </Button>

          {/* <Button
            className="btn-learn btns bg-transparent"
            onClick={handleShow}
          >
            {t("letsTalk")}
          </Button> */}
        </div>
      </div>
      <div className="right-img banner-image-div">
        <img src={bannerItem?.img} alt="" />
      </div>
      <BookModal bookingFor={bookingFor} />
    </>
  );
}

export default BannerText;
